import { Node } from "@tiptap/core";

const imageContainer = Node.create({
    name: "imageContainer",
    group: "block",
    content: "figure+",
    draggable: true,
    parseHTML() {
      return [{ tag: "div[data-type='image-container']" }];
    },
    renderHTML({ HTMLAttributes }) {
      return ["div", { "data-type": "image-container" }, 0];
    },
  });

const figure = Node.create({
    name: "figure",
    group: "block",
    content: "image imageCaption",
    draggable: true,
    parseHTML() {
      return [{ tag: "figure" }];
    },
    renderHTML({ HTMLAttributes }) {
      return ["figure", HTMLAttributes, 0];
    },
});
  
const imageCaption = Node.create({
    name: "imageCaption",
    group: "block",
    content: "inline*",
    parseHTML() {
      return [{ tag: "imageCaption" }];
    },
    renderHTML({ HTMLAttributes }) {
      return ["imageCaption", HTMLAttributes, 0];
    },
});

export { imageContainer, figure, imageCaption };
