import React, { useState } from "react";
import "../css/header.scss"
import "../css/font.scss"
import { useNavigate,useLocation } from "react-router-dom";
import { gsap } from "gsap"; 
import AIPDLOGO from '../image/aipd.png'

const Header = () => {
  const location = useLocation();
  // const goHomeTop=()=>{
  //   window.scrollTo(0, 0);
  //   // gsap.to(window, { duration: 1, scrollTo: { y: 0 } });
  // }

  const navigate = useNavigate(); 
  function goPage(url) {
    // if(location.pathname==='/'&&url==='/'){
    //   console.log('?',location.pathname)
    //   goHomeTop()
    // }else{
      // window.scrollTo(0, 0);
      navigate(url) 
    // }
  }
  function goNav(data,index,event) {
    event.stopPropagation();
    if(data.contents){
      // if(index==1){
      //   setOpenProduct(true)
      // }else{
      //   setOpenProduct(false)
      // }
    }else{
      if(index==0){
        goPage(data.url)
      }
    }
  }

  const [nav,setnav]=useState([
    {
      title:'Home',
      url:'/'
    },{
      title:'Our Products',
      url:'/',
      contents:[{
        key:'Computer Version',
        contents:[{
            title:'Fire ant Detection',
            url:'/FireAnt'
          },{
            title:'Termite Detection',
            url:'/TermitePage'
          }]
        },{
          key:'Report Writing',
          contents:[{
              title:'Edit Tool',
              url:'/Report'
            }]
          }]
    },{
      title:'About Us',
      url:'/AboutUs'
    },{
      title:'Contact Us',
      url:'/ContactUs'
    }
  ]);


  return (
    <header className="headercover" onClick={()=>goPage('/')}>
      {/* <div>AIPD</div> */}
      <div className="header">
        <div className="titleArea">
          {/* <img className = "logo" src= {AIPDLOGO} alt="AIPD logo"/> */}
          <div className="textContent">
            <div className="title">AIPD</div>
            <div className="subTitle">AI powered Detection</div>
          </div>
        </div>

        <div className="navs">
          {nav.map((nav,index)=>(
            <div className="nav" key={index+'nav'}>
            <h5
              onClick={(event)=>goNav(nav,0,event)}
            >
              {nav.title}
            </h5>
            {nav.contents?
              <div className="subNav">
                {nav.contents.map((subnab,j)=>(
                  <div key={j+'subnav'}>
                  <div className="subText">{subnab.key}</div>
                    {subnab.contents.map((subTitle,i)=>(
                      <h6 key={i+'subtitle'} className="" onClick={(event)=>goNav(subTitle,0,event)}>{subTitle.title}</h6>
                    ))} 
                </div>
                ))}
              </div>
            :
              <div></div>
            }
            </div>

          ))}
          
        </div>
      </div>
      {/* <Chatbot></Chatbot> */}
    </header>
  );
};

export default Header;